<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">Pengumuman</h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting"
      >
        Simpan
      </button>
    </div>

    <div class="p-24">
      <InputField
        label="Judul"
        required
        v-model="input.title"
        :error="errors.title"
      />
      <RichTextField
        class="mt-16"
        label="Deskripsi"
        v-model="input.content"
        :error="errors.content"
      />
      <FileField
        class="mt-16"
        label="File"
        v-model="input.file"
        :error="errors.file"
      />
      <RoleField
        class="mt-16"
        label="Role"
        required
        v-model="input.roles"
        :error="errors.roles"
      />
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import announcementRepository from '../../../api/repositories/announcementRepository';

import FileField from '../../form/FileField.vue';
import InputField from '../../form/InputField.vue';
import RichTextField from '../../form/RichTextField.vue';
import RoleField from '../../role/RoleField.vue';

export default {
  components: { FileField, InputField, RichTextField, RoleField },

  props: ['announcement'],

  data() {
    return { errors: {}, input: this.getInitialInput(), isSubmitting: false };
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;

        if (this.input.id) {
          await announcementRepository.update(this.input);
          this.$alert.success('Pengumuman berhasil diubah');
        } else {
          await announcementRepository.store(this.input);
          this.$alert.success('Pengumuman berhasil dibuat', {
            onOK: () => this.$router.push('/admin/pengumuman'),
          });
        }

        this.$store.commit('announcements/invalidate');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    getInitialInput() {
      const input = this.announcement ? this.announcement.$toJson() : {};
      input.roles = (input.roles || []).map(({ code }) => code);
      return input;
    },
  },

  watch: {
    announcement() {
      this.input = this.getInitialInput();
    },
  },
};
</script>
