<template>
  <MultiCheckField
    :attrs="{ type: 'checkbox' }"
    :options="options"
    v-bind="$props"
    @input="$emit('input', $event)"
  />
</template>

<script>
import BaseField from '../form/BaseField.vue';
import MultiCheckField from '../form/MultiCheckField.vue';

export default {
  extends: BaseField,

  components: { MultiCheckField },

  computed: {
    options() {
      return this.$store.getters['roles/get']().map((role) => ({
        value: role.code,
        label: role.name,
      }));
    },
  },

  beforeMount() {
    this.$store.dispatch('roles/maybeFetch');
  },
};
</script>
