<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <AnnouncementForm :announcement="announcement" />
  </main>
</template>

<script>
import Announcement from '../../../store/models/Announcement';

import AnnouncementForm from '../../../components/admin/announcement/AnnouncementForm.vue';

export default {
  components: { AnnouncementForm },

  computed: {
    announcement() {
      return Announcement.query().withAll().find(this.$route.params.id);
    },
  },

  metaInfo() {
    return { title: this.announcement && this.announcement.title };
  },

  beforeMount() {
    if (!this.announcement) {
      this.$store.dispatch('announcements/fetch', {
        filter: { id: this.$route.params.id },
      });
    }

    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/pengumuman', label: 'Pengumuman' },
    ]);
  },
};
</script>
