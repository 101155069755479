<template>
  <div>
    <label v-if="label"
      >{{ label }} <span v-if="required" class="required">*</span></label
    >
    <CheckField
      v-for="option of options"
      :attrs="{ ...attrs, value: option.value }"
      :class="{ 'is-invalid': error }"
      :key="option.value"
      :label="option.label"
      :value="value && value.includes(option.value)"
      @input="toggleOption(option, $event)"
    />

    <div class="invalid-feedback" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import without from 'lodash/without';

import BaseField from './BaseField.vue';
import CheckField from './CheckField.vue';

export default {
  extends: BaseField,

  props: ['options'],

  components: { CheckField },

  methods: {
    toggleOption(option, value) {
      const input = without(this.value || [], option.value);

      if (value) input.push(value);

      this.$emit('input', input);
    },
  },
};
</script>
