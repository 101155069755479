<template>
  <div class="form-check">
    <input
      class="form-check-input"
      v-bind="attrs"
      :id="id"
      :class="{ 'is-invalid': error }"
      :checked="value"
      @input="$emit('input', $event.target.checked && (attrs.value || true))"
    />

    <label class="form-check-label" v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>

    <div class="invalid-feedback" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import BaseField from './BaseField';

export default {
  extends: BaseField,
};
</script>

<style lang="scss" scoped>
.form-check-input {
  .is-invalid & {
    border-color: $form-feedback-invalid-color;
  }
}

.form-check-label {
  .is-invalid & {
    color: $form-feedback-invalid-color;
  }
}
</style>
